/*
 * @Description:
 * @Version:
 * @Author: 陈莉莉
 * @Date: 2022-01-05 09:56:50
 */
import { axios } from '@/utils/request'
let OSS = require('ali-oss')
let client = new OSS({
  accessKeyId: 'LTAI4GJRj5QAyoy9vDXDZBob',
  accessKeySecret: ' C8DD8e0KHKogS9aPTbWk96LBvHdabx',
  bucket: 'dianqixiaoshangcheng',
  region: 'https://dianqixiaoshangcheng.oss-cn-beijing.aliyuncs.com',
  endpoint: 'oss-cn-beijing.aliyuncs.com',
})
// oss分片上传
export const uploadFile = async (ObjName, fileUrl, progressFun) => {
  ObjName = parseInt(Math.random() * 100000 + 1, 10) + '.' + ObjName.split('.')[ObjName.split('.').length - 1]
  console.log(ObjName)
  try {
    var result = null
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    let day = new Date().getDate()
    let time = new Date().getTime()
    result = await client.multipartUpload(`${year}${month}${day}${time}${ObjName}`, fileUrl, progressFun)
    return result
  } catch (e) {
    console.log(e)
  }
}

// 暂停上传
export function cancelApi() {
  return client.cancel()
}
